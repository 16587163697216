import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { ApiService } from '@core/services/api/api.service';
import {
  ProjectImage,
  ProjectImageDetails,
  ProjectImagesQuery,
  ProjectImageUploadSession,
  ProjectSearchImageModel,
  ProjectSearchImagesQuery,
  ProjectImagesUserSettings,
  ProjectImageUserSettingResult,
  ProjectImageDetailsObject,
  ProjectImageDetailsTag,
  ImagesLink,
} from 'ngx-q360-lib';
import { QueryFilterModelNew, QueryModel } from 'ngx-q360-lib';
import { ProjectImageAuditItem } from 'ngx-q360-lib';
import {
  ProjectGetImageSettingsPayload,
  ProjectImageCreateUploadSessionPayload,
  ProjectImageDeleteObjectPayload,
  ProjectImageUpdateImagePayload,
  ProjectImageUpdateObjectPayload,
  ProjectImageUpdateTagPayload,
  ProjectSaveImageSettingsPayload,
} from 'ngx-q360-lib';

@Injectable({
  providedIn: 'root',
})
export class ProjectImagesService extends ApiService {
  constructor(http: HttpClient) {
    super(http);
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.baseUrl = environment.apiUrl;
    this.apiEndpoint = 'ProjectImage';
  }

  private makeProjectImagesRequest(
    method: string,
    endpoint: string,
    params?: { [key: string]: any } | undefined,
    responseType?: 'json' | 'blob',
    observe?: 'body' | 'events' | 'response',
  ): Observable<any> {
    return this.makeRequest(method, endpoint, params, responseType, observe).pipe(
      catchError((error) => this.handleError(error)),
    );
  }
  private handleError(error: any): any {
    return throwError(error);
  }

  getImage(projectId: string, imageId: string): Observable<ProjectImageDetails> {
    return this.makeProjectImagesRequest('GET', `Project/${projectId}/Image/${imageId}`);
  }

  deleteImage(projectId: string, imageId: string): Observable<ProjectImageDetails> {
    return this.makeProjectImagesRequest('DELETE', `Project/${projectId}/Image/${imageId}`);
  }

  bulkDelete(projectId: string, ids: string[]): Observable<void> {
    return this.makeProjectImagesRequest('PUT', `Project/${projectId}/Image/BulkDelete`, { ids });
  }

  bulkUpdate(projectId: string, payload: ProjectImageUpdateImagePayload[]): Observable<void> {
    return this.makeProjectImagesRequest('PUT', `Project/${projectId}/Image/BulkUpdate`, { images: payload });
  }

  getImages(projectId: string, query?: ProjectImagesQuery): Observable<QueryModel<ProjectImage>> {
    return this.makeProjectImagesRequest('POST', `Project/${projectId}/Image/GetImages`, query);
  }

  searchImages(projectId: string, query?: ProjectSearchImagesQuery): Observable<ProjectSearchImageModel> {
    return this.makeProjectImagesRequest('POST', `Project/${projectId}/Image/Search`, query);
  }

  getUploadSession(projectId: string, query: QueryFilterModelNew): Observable<QueryModel<ProjectImageUploadSession>> {
    return this.makeProjectImagesRequest('GET', `Project/${projectId}/UploadSession`, query);
  }

  getUploadSessionReport(projectId: string, id: string): Observable<HttpResponse<unknown>> {
    return this.makeProjectImagesRequest(
      'GET',
      `Project/${projectId}/UploadSession/${id}/report`,
      {},
      'blob',
      'response',
    );
  }

  setUploadSession(
    projectId: string,
    payload: ProjectImageCreateUploadSessionPayload,
  ): Observable<ProjectImageUploadSession> {
    return this.makeProjectImagesRequest('POST', `Project/${projectId}/UploadSession`, payload);
  }

  updateImage(projectId: string, imageId: string, payload: ProjectImageUpdateImagePayload): Observable<ProjectImage> {
    return this.makeProjectImagesRequest('PUT', `Project/${projectId}/Image/${imageId}`, payload);
  }

  saveSettings(payload: ProjectSaveImageSettingsPayload): Observable<ProjectImagesUserSettings> {
    return this.makeProjectImagesRequest('POST', 'Settings', payload);
  }

  getSettings(payload: ProjectGetImageSettingsPayload): Observable<ProjectImageUserSettingResult> {
    return this.makeProjectImagesRequest('GET', 'Settings', payload);
  }

  updateObject(
    projectId: string,
    imageId: string,
    payload: ProjectImageUpdateObjectPayload,
  ): Observable<ProjectImageDetailsObject> {
    return this.makeProjectImagesRequest('PUT', `Project/${projectId}/Image/${imageId}/DetectedObject`, payload);
  }

  deleteObject(
    projectId: string,
    imageId: string,
    payload: ProjectImageDeleteObjectPayload,
  ): Observable<ProjectImageDetailsTag> {
    return this.makeProjectImagesRequest('DELETE', `Project/${projectId}/Image/${imageId}/DetectedObject`, payload);
  }

  updateTag(
    projectId: string,
    imageId: string,
    name: string,
    payload: ProjectImageUpdateTagPayload,
  ): Observable<ProjectImageDetailsTag> {
    return this.makeProjectImagesRequest('PUT', `Project/${projectId}/Image/${imageId}/Tag/${name}`, payload);
  }

  createTag(projectId: string, imageId: string, name: string): Observable<ProjectImageDetailsTag> {
    return this.makeProjectImagesRequest('POST', `Project/${projectId}/Image/${imageId}/Tag/${name}`);
  }

  getTags(projectId: string, imageId: string, payload: QueryFilterModelNew): Observable<string[]> {
    return this.makeProjectImagesRequest('GET', `Project/${projectId}/Image/${imageId}/Tags`, payload);
  }

  deleteTag(projectId: string, imageId: string, name: string): Observable<ProjectImageDetailsTag> {
    return this.makeProjectImagesRequest('DELETE', `Project/${projectId}/Image/${imageId}/Tag/${name}`);
  }

  getProjectImageAudit(projectId: string, imageId: string): Observable<QueryModel<ProjectImageAuditItem>> {
    return this.makeProjectImagesRequest('GET', `Project/${projectId}/Audit/${imageId}`);
  }

  revertProjectImageAudit(projectId: string, auditId: string): Observable<ProjectImageAuditItem> {
    return this.makeProjectImagesRequest('POST', `Project/${projectId}/Audit/revert/${auditId}`);
  }

  getImagesByLink(projectId: string, imageLinkId: string): Observable<QueryModel<ProjectImage>> {
    return this.makeProjectImagesRequest('GET', `Project/${projectId}/Image/link/${imageLinkId}`);
  }

  createImagesLink(projectId: string, ids: string[]): Observable<ImagesLink> {
    return this.makeProjectImagesRequest('POST', `Project/${projectId}/Image/BulkLink`, { ids });
  }
}
