import { inject, Injectable } from '@angular/core';
import { Action, Actions, NgxsOnInit, ofActionSuccessful, State, StateContext } from '@ngxs/store';
import {
  defaultState,
  ProjectImageAnalyticsStateModel,
} from '@project/store/image-analytics/project-image-analytics.model';
import {
  BulkUpdateProjectImage,
  CreateImagesLink,
  CreateProjectImageTag,
  DeleteProjectImage,
  DeleteProjectImageObject,
  DeleteProjectImages,
  DeleteProjectImageTag,
  GetImagesByLink,
  GetMoreProjectImages,
  GetProjectImage,
  GetProjectImageAudit,
  GetProjectImages,
  GetProjectImageTags,
  GetProjectImageUploadSession,
  GetSettings,
  ProjectImageSearch,
  RevertProjectImageAudit,
  SaveSettings,
  SetProjectImageSearchQuery,
  SetProjectImagesQuery,
  SetProjectImageUploadSession,
  SetProjectImageUploadSessionQuery,
  UpdateProjectImage,
  UpdateProjectImageInSession,
  UpdateProjectImageObject,
  UpdateProjectImageTag,
  UpdateSelectedImageIds,
} from '@project/store/image-analytics/project-image-analytics.actions';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ProjectImagesService } from '@core/services/api/project-images.service';

import {
  AuditType,
  AzureBlobStorageService,
  EntityType,
  ifNotNull,
  NotifierService,
  ProjectImageErrorType,
  ProjectImagesQuery,
  ProjectImageStatus,
  ProjectSearchImagesQuery,
} from 'ngx-q360-lib';
import { ceil, isEqual } from 'lodash';
import { formatQueryFilterPayload } from '@core/helpers/global.helper';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
@State<ProjectImageAnalyticsStateModel>({
  name: 'projectImages',
  defaults: defaultState,
})
export class ProjectImageAnalyticsState implements NgxsOnInit {
  private projectImageService = inject(ProjectImagesService);
  private actions$ = inject(Actions);
  private azureBlobStorageService = inject(AzureBlobStorageService);
  private notifierService = inject(NotifierService);

  ngxsOnInit(ctx: StateContext<ProjectImageAnalyticsStateModel>) {
    this.actions$.pipe(ofActionSuccessful(SetProjectImageSearchQuery)).subscribe((action) => {
      if (!action.dispatch) {
        return;
      }

      ctx.dispatch(new ProjectImageSearch(action.projectId));
      ctx.patchState({
        imagesQuery: { ...ctx.getState().imagesQuery, ...ctx.getState().searchedImagesQuery, page: 0 },
      });
    });

    this.actions$.pipe(ofActionSuccessful(SetProjectImagesQuery)).subscribe((action) => {
      if (!action.dispatch) {
        return;
      }

      ctx.dispatch(new GetProjectImages(action.projectId));
    });

    this.actions$.pipe(ofActionSuccessful(SetProjectImageUploadSessionQuery)).subscribe((action) => {
      ctx.dispatch(new GetProjectImageUploadSession(action.projectId));
    });

    this.actions$.pipe(ofActionSuccessful(GetProjectImageUploadSession)).subscribe(() => {
      ctx.patchState({
        uploadSessionsQuery: {
          ...ctx.getState().uploadSessionsQuery,
          page: this.getSessionsPageCount(ctx),
        },
      });
    });

    this.actions$.pipe(ofActionSuccessful(SetProjectImageUploadSession)).subscribe((action) => {
      const findSession = ctx
        .getState()
        .uploadSessions.find((ses) => !!ses.images.find((img) => img.id === action.payload.images[0].id));

      if (findSession) {
        ctx.setState(
          patch({
            uploadingImages: insertItem({
              sessionId: findSession.id,
              images: action.payload.images.map((img) => img.id),
            }),
          }),
        );
      }

      action.fileList.forEach(async (list) => {
        if (list.error) {
          ctx.dispatch(
            new UpdateProjectImage(action.projectId, list.id, {
              status: ProjectImageStatus.InitializedWithError,
              errorType: list.error,
            }),
          );
          return;
        }

        const file = list.file;

        const ext = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
        const name = `${ctx.getState().uploadFolder}/${list.id}.${ext}`;
        const response = this.azureBlobStorageService.uploadFile(action.projectSas.url, list.file, name);
        response.response
          .then(() => {
            ctx.dispatch(new UpdateProjectImage(action.projectId, list.id, { status: ProjectImageStatus.Uploaded }));
          })
          .catch(() => {
            ctx.dispatch(
              new UpdateProjectImage(action.projectId, list.id, {
                status: ProjectImageStatus.UploadFailed,
                errorType: ProjectImageErrorType.UploadFailed,
              }),
            );
          });
      });
    });

    this.actions$.pipe(ofActionSuccessful(UpdateProjectImage)).subscribe((action) => {
      const findSession = ctx
        .getState()
        .uploadSessions.find((ses) => !!ses.images.find((img) => img.id === action.imageId));

      if (!findSession) {
        return;
      }

      ctx.setState(
        patch({
          uploadingImages: updateItem(
            (item) => item.sessionId === findSession.id,
            patch({ images: removeItem((image) => image === action.imageId) }),
          ),
        }),
      );

      const uploadingSession = ctx.getState().uploadingImages.find((session) => session.sessionId === findSession.id);
      if (uploadingSession?.images.length === 0) {
        ctx.setState(patch({ uploadingImages: removeItem((item) => item.sessionId === findSession.id) }));
      }
    });

    this.actions$
      .pipe(ofActionSuccessful(SaveSettings))
      .subscribe((action) => ctx.dispatch(new GetSettings({ projectId: action.payload.projectId })));

    this.actions$
      .pipe(
        ofActionSuccessful(
          UpdateProjectImageObject,
          UpdateProjectImageTag,
          DeleteProjectImageTag,
          CreateProjectImageTag,
          DeleteProjectImageObject,
        ),
      )
      .subscribe((action) => {
        if (!ctx.getState().selectedImage?.editedImageAnalysis) {
          ctx.dispatch(new GetProjectImage(action.projectId, action.imageId));
        }
      });

    this.actions$.pipe(ofActionSuccessful(RevertProjectImageAudit)).subscribe((action: RevertProjectImageAudit) => {
      const { audit } = action;
      switch (audit.entityType) {
        case EntityType.Tag:
          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  imageAnalysis: patch({
                    tags:
                      audit.auditType === AuditType.Create
                        ? removeItem(
                            (item) =>
                              item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                          )
                        : audit.oldValue
                          ? audit.auditType === AuditType.Update
                            ? updateItem(
                                (item) =>
                                  item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                                { ...audit.oldValue, auditId: null },
                              )
                            : append([{ ...audit.oldValue, auditId: uuidv4() }])
                          : ctx.getState().selectedImage?.imageAnalysis.tags,
                  }),
                  editedImageAnalysis: ifNotNull(
                    patch({
                      tags:
                        audit.auditType === AuditType.Create
                          ? removeItem(
                              (item) =>
                                item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                            )
                          : audit.oldValue
                            ? audit.auditType === AuditType.Update
                              ? updateItem(
                                  (item) =>
                                    item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                                  { ...audit.oldValue, auditId: null },
                                )
                              : append([{ ...audit.oldValue, auditId: uuidv4() }])
                            : ctx.getState().selectedImage?.editedImageAnalysis?.tags,
                    }),
                  ),
                }),
              ),
            }),
          );
          break;
        case EntityType.DetectedObject:
          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  imageAnalysis: patch({
                    objects:
                      audit.auditType === AuditType.Create
                        ? removeItem(
                            (item) =>
                              item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                          )
                        : audit.oldValue
                          ? audit.auditType === AuditType.Update
                            ? updateItem(
                                (item) =>
                                  item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                                { ...audit.oldValue, auditId: null },
                              )
                            : append([{ ...audit.oldValue, auditId: uuidv4() }])
                          : ctx.getState().selectedImage?.imageAnalysis.objects,
                  }),
                  editedImageAnalysis: ifNotNull(
                    patch({
                      objects:
                        audit.auditType === AuditType.Create
                          ? removeItem(
                              (item) =>
                                item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                            )
                          : audit.oldValue
                            ? audit.auditType === AuditType.Update
                              ? updateItem(
                                  (item) =>
                                    item.name === audit.newValue.name && item.confidence === audit.newValue.confidence,
                                  { ...audit.oldValue, auditId: null },
                                )
                              : append([{ ...audit.oldValue, auditId: uuidv4() }])
                            : ctx.getState().selectedImage?.editedImageAnalysis?.objects,
                    }),
                  ),
                }),
              ),
            }),
          );

          break;
        case EntityType.Location:
          if (!audit.oldValue) {
            break;
          }

          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  location: audit.oldValue,
                }),
              ),
            }),
          );

          break;

        case EntityType.ImageTakenAt:
          if (!audit.oldValue) {
            break;
          }

          ctx.setState(
            patch({
              selectedImage: ifNotNull(
                patch({
                  imageTakenAt: audit.oldValue,
                }),
              ),
            }),
          );

          break;
        default:
          return;
      }
    });
  }

  @Action(ProjectImageSearch, { cancelUncompleted: true })
  public projectImageSearch(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: ProjectImageSearch) {
    const currentQuery = ctx.getState().searchedImagesQuery;
    const newQuery = {
      ...currentQuery,
      yearMonth: currentQuery.yearMonth.map((ym) => {
        if (ym === '-1--1') {
          return null;
        }
        return ym;
      }),
    } as ProjectSearchImagesQuery;

    if (newQuery.withLocation === 'all') {
      delete newQuery.withLocation;
    }

    ctx.patchState({ searchedImagesLoading: true });

    return this.projectImageService.searchImages(action.projectId, newQuery).pipe(
      tap((result) => {
        ctx.patchState({ searchedImagesLoading: false });
        ctx.patchState({ currentSearchedImagesQuery: newQuery });
        ctx.patchState({ searchedImages: result });
      }),
      catchError((err) => {
        ctx.patchState({ searchedImagesLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(SetProjectImageSearchQuery)
  public projectSearchImagesQuery(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: SetProjectImageSearchQuery,
  ) {
    const query = {
      ...ctx.getState().searchedImagesQuery,
      ...formatQueryFilterPayload<ProjectSearchImagesQuery>(action.query),
    };

    ctx.setState(
      patch<ProjectImageAnalyticsStateModel>({
        searchedImagesQuery: query,
      }),
    );
  }

  @Action(GetProjectImageUploadSession)
  public getProjectImageUploadSession(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: GetProjectImageUploadSession,
  ) {
    const page = ctx.getState().uploadSessionsQuery.page;
    const count = ctx.getState().uploadSessionsCount;
    const sessionsLength = ctx.getState().uploadSessions.length;

    if (count > 0 && count === sessionsLength && page !== 0) {
      return EMPTY;
    }

    ctx.patchState({ uploadSessionsLoading: true });
    return this.projectImageService.getUploadSession(action.projectId, ctx.getState().uploadSessionsQuery).pipe(
      tap((result) => {
        ctx.patchState({ uploadSessionsLoading: false });
        ctx.patchState({
          uploadSessions: page === 0 ? result.items : [...ctx.getState().uploadSessions, ...result.items],
          uploadSessionsCount: result.totalCount,
        });
      }),
      catchError((err) => {
        ctx.patchState({ uploadSessionsLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(SetProjectImageUploadSessionQuery)
  public setUploadSessionQuery(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: SetProjectImageUploadSessionQuery,
  ) {
    const currentPage = this.getSessionsPageCount(ctx);
    const newPage = currentPage <= action.query.page ? action.query.page : currentPage;
    const query = {
      ...action.query,
      page: action.query.page === 0 ? action.query.page : newPage,
    };

    ctx.patchState({ uploadSessionsQuery: query });
  }

  @Action(SetProjectImageUploadSession)
  public setProjectImageUploadSession(
    ctx: StateContext<ProjectImageAnalyticsStateModel>,
    action: SetProjectImageUploadSession,
  ) {
    return this.projectImageService.setUploadSession(action.projectId, action.payload).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            uploadSessions: insertItem(result),
            uploadSessionsCount: ctx.getState().uploadSessionsCount + 1,
          }),
        );
      }),
    );
  }

  @Action(UpdateProjectImage)
  updateProjectImage(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImage) {
    return this.projectImageService.updateImage(action.projectId, action.imageId, action.payload).pipe(
      tap((result) => {
        // if we get status Uploaded but image is already Processed or ProcessedWithError,
        // don't update in the store (socket can be faster than request response)
        const findSession = ctx
          .getState()
          .uploadSessions.find((us) => !!us.images.find((image) => image.id === action.imageId));
        if (findSession) {
          const image = findSession.images.find((img) => img.id === action.imageId);
          if (
            image &&
            [ProjectImageStatus.Processed, ProjectImageStatus.ProcessedWithError].includes(image.status) &&
            action.payload.status === ProjectImageStatus.Uploaded
          ) {
            return EMPTY;
          }
        }

        ctx.setState(
          patch({
            uploadSessions: updateItem(
              (item) => item.id === result.uploadSessionId,
              patch({ images: updateItem((image) => image.id === result.id, result) }),
            ),
            selectedImage: ifNotNull(
              patch({
                ...ctx.getState().selectedImage,
                location: result.location,
                imageTakenAt: result.imageTakenAt,
                status: result.status,
              }),
            ),
          }),
        );

        if (action.showAlert) {
          this.notifierService.success('Image updated.', {
            autoClose: true,
            keepAfterRouteChange: true,
          });
        }
      }),
      catchError((err) => {
        if (action.showAlert) {
          this.notifierService.error('Image update failed.', {
            autoClose: true,
            keepAfterRouteChange: true,
          });
        }

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(BulkUpdateProjectImage)
  bulkUpdateProjectImage(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: BulkUpdateProjectImage) {
    ctx.patchState({ bulkUpdateLoading: true });
    return this.projectImageService.bulkUpdate(action.projectId, action.payload).pipe(
      tap(() => {
        ctx.patchState({ bulkUpdateLoading: false });
        const updatedImages = ctx.getState().images.map((image) => {
          const payload = action.payload.find((p) => p.id === image.id) || {};
          return { ...image, ...payload };
        });

        ctx.patchState({ images: updatedImages });

        this.notifierService.success('Images updated.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        ctx.patchState({ bulkUpdateLoading: false });
        this.notifierService.error('Images update failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateProjectImageInSession)
  updateProjectImageInSession(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImageInSession) {
    ctx.setState(
      patch({
        uploadSessions: updateItem(
          (item) => item.id === action.payload.uploadSessionId,
          patch({ images: updateItem((image) => image.id === action.payload.id, action.payload) }),
        ),
      }),
    );
  }

  @Action(GetProjectImages)
  getImages(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetProjectImages) {
    ctx.patchState({ imagesLoading: true });
    const currentQuery = ctx.getState().imagesQuery;
    const newQuery = {
      ...currentQuery,
      yearMonth: currentQuery.yearMonth.map((ym) => {
        if (ym === '-1--1') {
          return null;
        }
        return ym;
      }),
    } as ProjectImagesQuery;

    if (newQuery.withLocation === 'all') {
      delete newQuery.withLocation;
    }

    if (newQuery.page === 0) {
      ctx.patchState({ images: [] });
    }

    return this.projectImageService.getImages(action.projectId, newQuery).pipe(
      tap((result) => {
        ctx.patchState({ imagesLoading: false });
        ctx.patchState({ images: result.items, imagesTotalCount: result.totalCount });
      }),
      catchError((err) => {
        ctx.patchState({ imagesLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetMoreProjectImages)
  getMoreProjectImages(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetMoreProjectImages) {
    const state = ctx.getState();

    if (!state.images.length || state.images.length === state.imagesTotalCount) {
      return EMPTY;
    }

    const currentQuery = state.imagesQuery;
    const newQuery = {
      ...currentQuery,
      yearMonth: currentQuery.yearMonth.map((ym) => {
        if (ym === '-1--1') {
          return null;
        }
        return ym;
      }),
    } as ProjectImagesQuery;

    ctx.patchState({ imagesLoadingMore: true });
    newQuery.page = state.imagesQuery.page + 1;

    if (newQuery.withLocation === 'all') {
      delete newQuery.withLocation;
    }

    return this.projectImageService.getImages(action.projectId, newQuery).pipe(
      tap((result) => {
        ctx.patchState({ imagesLoadingMore: false });
        if (result.items.length) {
          ctx.patchState({
            images: [...ctx.getState().images, ...result.items],
            imagesQuery: newQuery,
          });
        }
      }),
      catchError((err) => {
        ctx.patchState({ imagesLoadingMore: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(SetProjectImagesQuery)
  setProjectImageQuery(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: SetProjectImagesQuery) {
    ctx.setState(
      patch<ProjectImageAnalyticsStateModel>({
        imagesQuery: {
          ...ctx.getState().imagesQuery,
          ...formatQueryFilterPayload<ProjectImagesQuery>(action.query),
        },
      }),
    );
  }

  @Action(SaveSettings)
  saveSettings(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: SaveSettings) {
    return this.projectImageService.saveSettings(action.payload).pipe(
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetSettings)
  getSettings(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetSettings) {
    ctx.patchState({ settingsLoading: true });
    return this.projectImageService.getSettings(action.payload).pipe(
      tap((result) => {
        ctx.patchState({ settings: result.items });
        ctx.patchState({ settingsLoading: false });
      }),
      catchError((err) => {
        ctx.patchState({ settingsLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateProjectImageObject)
  updateProjectImageObject(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImageObject) {
    return this.projectImageService.updateObject(action.projectId, action.imageId, action.payload).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                editedImageAnalysis: ifNotNull(
                  patch({
                    objects: updateItem((object) => isEqual(object, action.payload.detectedObject), {
                      ...result,
                      auditId: uuidv4(),
                    }),
                  }),
                ),
              }),
            ),
          }),
        );
        this.notifierService.success('Object updated.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Object update failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(DeleteProjectImageObject)
  deleteProjectImageObject(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: DeleteProjectImageObject) {
    const { detectedObject } = action.payload;
    return this.projectImageService.deleteObject(action.projectId, action.imageId, action.payload).pipe(
      tap(() => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                editedImageAnalysis: ifNotNull(
                  patch({
                    objects: removeItem(
                      (object) =>
                        object.name === detectedObject.name &&
                        object.confidence === detectedObject.confidence &&
                        object.boundingBox === detectedObject.boundingBox,
                    ),
                  }),
                ),
              }),
            ),
          }),
        );
        this.notifierService.success('Object deleted.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Object deletion failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateProjectImageTag)
  updateProjectImageTag(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateProjectImageTag) {
    return this.projectImageService.updateTag(action.projectId, action.imageId, action.name, action.payload).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                editedImageAnalysis: ifNotNull(
                  patch({
                    tags: updateItem((tag) => tag.name === action.name, { ...result, auditId: uuidv4() }),
                  }),
                ),
              }),
            ),
          }),
        );
        this.notifierService.success('Tag updated.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Tag update failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(DeleteProjectImageTag)
  deleteProjectImageTag(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: DeleteProjectImageTag) {
    return this.projectImageService.deleteTag(action.projectId, action.imageId, action.name).pipe(
      tap(() => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                editedImageAnalysis: ifNotNull(
                  patch({
                    tags: removeItem((tag) => tag.name === action.name),
                  }),
                ),
              }),
            ),
          }),
        );
        this.notifierService.success('Tag deleted.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Tag deletion failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(CreateProjectImageTag)
  createProjectImageTag(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: CreateProjectImageTag) {
    return this.projectImageService.createTag(action.projectId, action.imageId, action.name).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            selectedImage: ifNotNull(
              patch({
                editedImageAnalysis: ifNotNull(
                  patch({
                    tags: append([{ ...result, auditId: uuidv4() }]),
                  }),
                ),
                imageAnalysis: patch({
                  tags: append([{ ...result, auditId: uuidv4() }]),
                }),
              }),
            ),
          }),
        );
        this.notifierService.success('Tag created.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Tag creation failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetProjectImage)
  getProjectImage(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetProjectImage) {
    ctx.patchState({ selectedImageLoading: true });
    return this.projectImageService.getImage(action.projectId, action.imageId).pipe(
      tap((result) => {
        ctx.patchState({ selectedImageLoading: false });
        ctx.patchState({ selectedImage: result });
      }),
      catchError((err) => {
        ctx.patchState({ selectedImageLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(DeleteProjectImage)
  deleteProjectImage(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: DeleteProjectImage) {
    return this.projectImageService.deleteImage(action.projectId, action.imageId).pipe(
      tap((result) => {
        ctx.patchState({ selectedImage: null });
        ctx.setState(patch({ images: removeItem((image) => image.id === result.id) }));
      }),
      catchError((err) => {
        this.notifierService.error('Image deletion failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(DeleteProjectImages)
  deleteProjectImages(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: DeleteProjectImages) {
    return this.projectImageService.bulkDelete(action.projectId, action.imageIds).pipe(
      tap(() => {
        ctx.patchState({ selectedImage: null });
        ctx.setState(patch({ images: ctx.getState().images.filter((image) => !action.imageIds.includes(image.id)) }));
        ctx.setState(
          patch({ selectedImagesIds: ctx.getState().selectedImagesIds.filter((id) => !action.imageIds.includes(id)) }),
        );
        this.notifierService.success('Images deleted.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }),
      catchError((err) => {
        this.notifierService.error('Image deletion failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetProjectImageTags)
  getProjectImageTags(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetProjectImageTags) {
    ctx.patchState({ selectedImageTagsLoading: true });
    return this.projectImageService.getTags(action.projectId, action.imageId, action.payload).pipe(
      tap((result) => {
        ctx.patchState({ selectedImageTagsLoading: false });
        ctx.patchState({ selectedImageTags: result });
      }),
      catchError((err) => {
        ctx.patchState({ selectedImageTagsLoading: false });
        ctx.patchState({ selectedImageTags: [] });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(GetProjectImageAudit, { cancelUncompleted: true })
  getProjectImageAudit(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetProjectImageAudit) {
    ctx.patchState({ selectedImageAuditLoading: true });
    return this.projectImageService.getProjectImageAudit(action.projectId, action.imageId).pipe(
      tap((result) => {
        ctx.patchState({ selectedImageAuditLoading: false });
        ctx.patchState({ selectedImageAudit: result });
      }),
      catchError((err) => {
        ctx.patchState({ selectedImageAuditLoading: false });
        ctx.patchState({ selectedImageAudit: { items: [], totalCount: 0 } });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(RevertProjectImageAudit)
  revertProjectImageAudit(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: RevertProjectImageAudit) {
    return this.projectImageService.revertProjectImageAudit(action.projectId, action.audit.id).pipe(
      tap((result) => {
        ctx.setState(
          patch({
            selectedImageAudit: patch({
              items: removeItem((item) => item.id === result.id),
              totalCount: ctx.getState().selectedImageAudit.totalCount - 1,
            }),
          }),
        );
      }),
      catchError((err) => {
        this.notifierService.error('Reverting failed.', {
          autoClose: true,
          keepAfterRouteChange: true,
        });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(UpdateSelectedImageIds)
  updateSelectedImageIds(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: UpdateSelectedImageIds) {
    ctx.patchState({ selectedImagesIds: action.ids });
  }

  @Action(GetImagesByLink)
  getImagesByLink(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: GetImagesByLink) {
    ctx.patchState({ imagesLoading: true });
    return this.projectImageService.getImagesByLink(action.projectId, action.imageLinkId).pipe(
      tap((result) => {
        ctx.patchState({ imagesLoading: false });
        ctx.patchState({ images: result.items, imagesTotalCount: result.totalCount });
      }),
      catchError((err) => {
        ctx.patchState({ imagesLoading: false });
        return throwError(() => new Error(err));
      }),
    );
  }

  @Action(CreateImagesLink)
  createImagesLink(ctx: StateContext<ProjectImageAnalyticsStateModel>, action: CreateImagesLink) {
    if (isEqual(ctx.getState().imageLinkIds, action.ids)) {
      return;
    }

    return this.projectImageService.createImagesLink(action.projectId, action.ids).pipe(
      tap((result) => {
        ctx.patchState({ imageLinkIds: action.ids });
        ctx.patchState({ imagesLink: result.id });
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  private getSessionsPageCount(ctx: StateContext<ProjectImageAnalyticsStateModel>) {
    return ceil(ctx.getState().uploadSessions.length / ctx.getState().uploadSessionsQuery.pageSize) - 1;
  }
}
